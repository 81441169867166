import React from 'react';
import './BookAppointment.css';

class BookAppointment extends React.Component {
  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.onload = () => {
      window.Calendly.initInlineWidget({
        url: 'https://calendly.com/chase-ai/consultation-appointment',
        parentElement: document.querySelector('.calander-embed'),
        prefill: {},
        utm: {}
      });
    }
    head.appendChild(script);
  }

  render() {
    return (
      <section className="calander-embed"></section>
    );
  }
}

export default BookAppointment;
