import React from 'react';

function ContactUs() {
  const handleClick = () => {
    if (window.botpressWebChat) {

      window.botpressWebChat.sendEvent({ type: "show" });
    }
  }

  return (
    <div className="button" onClick={handleClick}>
      Contact Us
    </div>

  );
}

export default ContactUs;
