import React from 'react';
import './Chat.css';

class Chatbot extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://cdn.botpress.cloud/webchat/v0/inject.js';
    script.async = true;

    document.body.appendChild(script);

    const initBotpress = setInterval(() => {
      if (window.botpressWebChat && typeof window.botpressWebChat.init === 'function') {
        window.botpressWebChat.init({
          "composerPlaceholder": "Reply to SecretaryBot",
          "stylesheet": "https://chase-ai-web.s3.us-west-1.amazonaws.com/public/chatbot.css",
          "botConversationDescription": "",
          "botId": "bbceeb72-5556-4bc2-a733-5d98f3344db1",
          "hostUrl": "https://cdn.botpress.cloud/webchat/v0",
          "messagingUrl": "https://messaging.botpress.cloud",
          "clientId": "bbceeb72-5556-4bc2-a733-5d98f3344db1",
          "botName": "SecretaryBot",
          "useSessionStorage": true,
          "enableConversationDeletion": false,
          "hideWidget": true,
          "disableAnimations": false,
          "showConversationsButton": false,
          "enableTranscriptDownload": false,
          "showTimestamp": false,
          "containerWidth": "480px",
          "layoutWidth": "480px",
          "avatarUrl": "https://chase-ai.com/botlogo.png"
        });
        clearInterval(initBotpress);

        // AUTO-START CONVERSATION
        window.botpressWebChat.onEvent(() => {
          if (window.botpressWebChat) {
            window.botpressWebChat.sendEvent({
              type: "trigger",
              payload: {},
            });
      
          }

        }, ["LIFECYCLE.LOADED"]);
      }

    }, 100); 


    return () => {
      document.body.removeChild(script);
    };
  }

  render() {
    return (
      <section></section>
    );
  }
}

export default Chatbot;
