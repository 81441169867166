import React, { useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Header() {
    const headerRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                // headerRef.current.style.backgroundColor = 'white'; 
            } else {
                // headerRef.current.style.backgroundColor = 'transparent';
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navigateHome = () => {
        navigate('/');
    }

    return (
        <header ref={headerRef}>
            

            {/* <a href="http://localhost:3000/">
                    <div className="logo-name"></div>
                </a> */}
            <a href="https://chase-ai.com">
                <div className="logo-name"></div>
            </a>


            <nav>
                {/* <Link to="/" className="nav-link">Home</Link>
                <Link to="/blog" className="nav-link">Blog</Link> */}
                {/* <Link to="/book-appointment" className="nav-link">Book an Appointment</Link> */}
            </nav>
            <div className="logo-art"></div>
        </header>
    );
}

export default Header;
