import { Link } from 'react-router-dom';
import Card from '../components/Card';
import ContactButton from '../components/ContactButton';
import Chatbot from './Chat';
import React from 'react';

function Home() {
    return (
        <div>
            <section style={{textAlign: 'right'}}>
                {/* Explore the Future of AI Communication with Sayable.ai */}
            </section>

            <section>
                <div className="hero-image">
                    
                <div className="hero-box">
                    <div className="hero-header">Blending Art and Technology</div>
                    <div className="hero-content">Where technology meets creativity, I pioneer AI solutions that transform organizations.</div>

                </div>
                </div>
            </section>



            <div className="section-divider"></div>


            <section>
                <br/>
                <div className="info-section-2 about-us">
                    <div className="info-section-2-header thin-header">
                        About Me
                    </div>
                    <div style={{display: "block"}}>
                        <img className="info-section-image"
                             src="dchase.png"
                             alt="Dale Chase"/>
                             
                   

                    <div onClick={  () => window.open('https://www.linkedin.com/in/dale-chase/', '_blank') } className="social-button"></div>
                    </div>
                    <div className="info-section-2-content">I'm Dale Chase, a seasoned Senior Software Engineer and entrepreneur from the SF Bay Area, deeply entrenched in AI, and software development. With an entrepreneurial spirit and a drive for innovation, I founded <a style={{color: '#7388a9'}} href="https://sayable.ai," target='_blank'>Sayable.ai</a> a cutting-edge platform transforming the way businesses interact with their customers through AI Voice Assistant secretaries and customer service agents.
<br/><br/>
My journey in tech spans notable positions with Skidmore, Owings & Merrill (SOM), Amazon Web Services (AWS), and Nimble Collective, where I led teams, designed data warehouses, and developed scalable services. These experiences honed my skills, enabling me to build complex, user-centric solutions.
<br/><br/>
Aside from my technical pursuits, I'm also known in the internet music scene for my hip hop song <a style={{color: '#7388a9'}} href="https://www.youtube.com/watch?v=B-m6JDYRFvk&pp=ygUVQ29kZXIgR2lybCBkYWxlIGNoYXNl" target='_blank'>"Coder Girl,"</a> showcasing my creativity. My passion for music and technology uniquely positions me to innovate at the intersection of both worlds.
<br/><br/>
If you're as excited about revolutionizing customer service with AI as I am, let's connect. 

</div><br />
                </div>
                <br/>
            </section>
            <div className="section-divider"></div>
            <Chatbot/>
        </div>
    );
}

export default Home;

