import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';

import Header from './components/Header';
import Home from  './pages/Home';
import BookAppointment from './pages/BookAppointment';
import SQLTranslate from './pages/SQLTranslate';


function App() {
    return (
        <Router>
            <MainApp />
        </Router>
    );
}


function MainApp() {

    const location = useLocation();
    const containerClass = location.pathname === '/' ? "container" : "container page";


    return (
        <div className="App">
                <div className={containerClass}>
                    <div className="header-wrapper">
                        <Header/>
                    </div>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/sql-translate" element={<SQLTranslate />} />
                        {/* <Route path="/blog" element={<Blog />} /> */}
                        <Route path="/appointments" element={<BookAppointment />} />
                    </Routes>

                </div>
        </div>
    );
}

export default App;
