import React, { useState } from 'react';
import './SQLTranslate.css';

function SQLTranslate() {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/translate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({input}),
      });
      const data = await response.json();
      setOutput(data.sqlOutput);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div> 
      <section>
      <div>
          <h1>Human to SQL Translator</h1>
        </div>
        <div>
          Tell it in plain english what you want from your database.<br />
          <br />
          For example: <br />
          Gimme all the users! = {'SELECT * FROM users;'}<br />
          <br />
        </div>
      </section>
      <section>
        <div className="info-section-2">
          <div className="activity-section-content">
            <textarea className='activity-section-textarea' value={input} onChange={e => setInput(e.target.value)} placeholder='Write your plain english query here.' />
            <button onClick={handleSubmit} disabled={input.trim() === ''}>Go!</button>
          </div>
          <div className="activity-section-content">
            <textarea className='activity-section-textarea'  value={output}/>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SQLTranslate;
